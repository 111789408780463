import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'; // Importa lo store Vuex
import LoginView from '../views/LoginView.vue'; // Importa la tua pagina di login
import { jwtVerify } from 'jose';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView, // La pagina di login
  },
  {
    path: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta: { requiresAuth: true }, // Anche questa richiede autenticazione
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { requiresAuth: true }, // Anche questa richiede autenticazione
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Decodifica la chiave base64
const base64ToUint8Array = (base64) => {
  const padding = '='.repeat((4 - (base64.length % 4)) % 4);
  const base64Cleaned = (base64 + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64Cleaned);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
async function decodeAndVerifyToken(token) {
  //const secretKey = new TextEncoder().encode('sWfwokN4oteptfyi3HjyNFX9IUOwKhElJkW3GMpJvvY=');
  const secretKey = base64ToUint8Array('sWfwokN4oteptfyi3HjyNFX9IUOwKhElJkW3GMpJvvY=');  
  try {
    const { payload } = await jwtVerify(token, secretKey, {
      algorithms: ['HS256'] // Assicurati che l'algoritmo corrisponda a quello utilizzato per firmare il token
    });
    return payload;
  } catch (error) {
    console.error('Error verifying token:', error);
    return null;
  }
}


// Route Guard con Vuex
router.beforeEach(async (to, from, next) => {
  const token = new URLSearchParams(window.location.search).get('token');  
  if (token) {
    const payload = await decodeAndVerifyToken(token);
    if (payload) {      
      sessionStorage.setItem('User', payload.email);
      if( payload.role == 'PRODUCER')
        sessionStorage.setItem('Profile', '2');    
      else if( payload.role == 'OPERATOR')
        sessionStorage.setItem('Profile', '1');    
      else if( payload.role == 'VIEWER'){
        sessionStorage.setItem('Profile', '9');    
        sessionStorage.setItem('ViewerPath', payload.path);      
      }else
        sessionStorage.setItem('Profile', '3');   

      sessionStorage.setItem('Role', payload.role);
      sessionStorage.setItem('Customers', payload.customers);
      
      console.log('route email:',payload.email);
      console.log('route role:',payload.role);
      console.log('route customers:',payload.customers);

      store.commit('setAuthenticated', true); // Imposta autenticazione
      if (to.path === '/') {
        next('/home'); // Reindirizza alla home solo se si trova nella pagina di login
      } else {
        next(); // Continua con la navigazione
      }
    } else {
      console.log('Token non valido');
      if (to.path === '/') {
        next('/'); // Reindirizza alla home solo se si trova nella pagina di login
      } else {
        next(); // Continua con la navigazione
      }
    }
  } else if (to.meta.requiresAuth && !store.state.isAuthenticated) {
    next({ path: '/' }); // Reindirizza alla pagina di login
  } else {
    next(); // Continua con la navigazione
  }
  /*
  if (to.meta.requiresAuth && !store.state.isAuthenticated) {
    next({ path: '/' }); // Reindirizza alla pagina di login
  } else {
    next(); // Continua con la navigazione
  }
  */
});

export default router
