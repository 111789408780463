<template>
    <div class="login-page" >
      <!-- Logo -->
      <div class="logo-container">
        <img src="../assets/E-Photo.png" alt="E-Photo Logo" class="logo"  />
      </div>
  
      <!-- Card con Utente e Password -->
      <div class="card login-card">
        <div class="card-body">
        
        <!-- Messaggio di Errore -->
        <div v-if="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
  
          <div class="form-group" >
            <label for="username">Username</label>
            <input
              type="text"
              id="username"
              class="form-control"
              v-model="username"
              placeholder="Enter your username"
            />
          </div>
  
          <div class="form-group">
            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              class="form-control"
              v-model="password"
              placeholder="Enter your password"
            />
          </div>
  
          <!-- Pulsante per il Login -->
          <button class="btn" style="background-color:#42b983;color: white" @click="login">Login</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapMutations } from 'vuex';
  export default {
    data() {
      return {
        username: '',
        password: '',
        errorMessage: '', // Variabile per memorizzare il messaggio di errore
      };
    },
    mounted() {
      sessionStorage.setItem('User', '');
      sessionStorage.setItem('Profile', '0');                
      sessionStorage.setItem('Role', '');
      sessionStorage.setItem('Customers', null);      
    },    
    methods: {
        ...mapMutations(['setAuthenticated']), // Mappa le mutazioni di Vuex

        async login() {
          const getCurrentDateAsPassword = () => {
            const currentDate = new Date();
            
            const month = currentDate.getMonth()+1; // Ottieni il mese e aggiungi uno zero se necessario
            const day = currentDate.getDate(); // Ottieni il giorno e aggiungi uno zero se necessario
            const year = currentDate.getFullYear(); // Ottieni l'anno

            // Calcola la somma numerica
            const totalSum = month + day + year;
            return totalSum; // Forma la password
          };
          const validPassword = 'E-Photo' + getCurrentDateAsPassword() + '!'; // Valore valido per la password
          this.$store.commit('setUser', this.username.toLocaleLowerCase());
          
          if (this.username.toLocaleLowerCase() == 'admin'){              
              if(this.password == validPassword) {
                console.log('Access Admin:',this.username.toLocaleLowerCase());
                
                sessionStorage.setItem('User', 'admin');
                sessionStorage.setItem('Profile', '0');
                
                this.setAuthenticated(true); // Imposta autenticazione
                this.$router.push('/home'); // Reindirizza alla home
              }else {
                  this.errorMessage = 'Password error'; // Mostra errore
              }
          } else {
            const validEmails = process.env.VUE_APP_EMAIL_LIST; // Indirizzi email validi separati da virgola
            if (!validEmails) {
              this.errorMessage = 'Email list is not defined'; // Mostra errore se la lista email non è definita
              return;
            }            
            const emailArray = validEmails.split(','); // Dividi la stringa in un array
            console.log('mail array: ',emailArray);

            const foundEmail = emailArray.find(email => email.split(':')[0] === this.username);
            if (foundEmail) {
              if (this.password === validPassword) {
                let user = this.username;
                let profile = foundEmail.split(':')[1];

                console.log('Access User:',user);

                sessionStorage.setItem('User', user);
                sessionStorage.setItem('Profile', profile);

                this.setAuthenticated(true); // Imposta autenticazione
                this.$router.push('/home'); // Reindirizza alla home
              } else {
                this.errorMessage = 'Email Password error'; // Mostra errore
              }
            } else {
              this.errorMessage = 'Email error'; // Mostra errore
            }


          }
        },
    },
  };
  </script>
  
  <style scoped>
  .login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Assicura che la pagina occupi l'intero schermo */
  }
  
  .logo-container {
    margin-bottom: 20px; /* Aggiungi uno spazio sotto il logo */  
  }
  
  .logo {
    width: 350px;
    margin-bottom: 20px; /* Aggiungi uno spazio sotto il logo */
    margin-left: 10px; /* Aggiungi uno spazio sotto il logo */
    margin-right: 10px; /* Aggiungi uno spazio sotto il logo */
  }
  @media (max-width: 800px) {
    .logo {
      width: 350px;
      margin-bottom: 20px; /* Aggiungi uno spazio sotto il logo */
      margin-left: 10px; /* Aggiungi uno spazio sotto il logo */
      margin-right: 10px; /* Aggiungi uno spazio sotto il logo */
    }
  }
  
  .login-card {
    width: 300px; /* Larghezza della card */
    padding: 20px; /* Spazio interno della card */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Effetto ombra per la card */
    border-radius: 10px; /* Angoli arrotondati */
  }
  
  .card-title {
    text-align: center; /* Allinea il titolo della card al centro */
  }
  
  .form-group {
    margin-bottom: 15px; /* Spazio tra i campi del form */
    text-align: left;
  }
  
  .btn {
    width: 100%; /* Pulsante che copre l'intera larghezza */
    margin-top: 10px; /* Aggiungi uno spazio sopra il pulsante */
  }
  </style>
  