
import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'; // Importa lo store Vuex

import { BootstrapVue3 } from 'bootstrap-vue-3';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

const app = createApp(App);

app.use(BootstrapVue3);
app.use(router); // Includi il router
app.use(store);  // Includi lo store Vuex

app.mount('#app');


