import { createStore } from 'vuex';

const store = createStore({
  state: {
    isAuthenticated: false, // Stato di autenticazione
    awsBucket: process.env.VUE_APP_AWS_BUCKET,
    user: null, // Stato dell'utente
  },
  mutations: {
    // Modifica lo stato di autenticazione
    setAuthenticated(state, status) {
      state.isAuthenticated = status;
    },
    setAwsBucket(state, newBucket) {
      state.awsBucket = newBucket;
    },
    setUser(state, user) {
      state.user = user; // Imposta l'utente
    },
  },
  actions: {
    // Azione per il login
    login({ commit }, { username, password }) {
        const validUsername = 'admin'; // Username valido
        const validPassword = '1234'; // Password valida
        console.log('store: ',username)
        if (username === validUsername && password === validPassword) {
            commit('setAuthenticated', true); // Autenticazione riuscita
        } else {
            throw new Error('Username o password errati');
        }
    },
    updateAwsBucket({ commit }, newBucket) {
      commit('setAwsBucket', newBucket);
    },
  },
  getters: {
    getAwsBucket: state => state.awsBucket,
    getUser: (state) => state.user, // Aggiungi getter per ottenere l'utente
  },
});

export default store;
