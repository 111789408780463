<template>
  <div>
    <!-- Navbar condizionale -->
    <nav v-if="showNavbar && profile <2" >
      <div class="row" >
      <div class="col-lg-1"></div>  
      <div class="col-lg-10" style="display: flex; justify-content: space-between; align-items: center;">      
        <div>
          <router-link to="/home">Home</router-link> |          
          <a @click="logout"> Exit</a> <!-- Link di uscita -->
        </div> 
        <div v-if="user" style="margin-right: 20px;">
          Welcome <strong>{{ getUser }}</strong>
        </div>
      </div>
    </div>
    </nav>
    <!-- Renderizza il contenuto della rotta corrente -->
    <router-view/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'; // Se usi Vuex per gestire l'autenticazione

export default {
  data() {
    return {
      user: '',
      profile: 0,
    };
  },  
  computed: {
    getUser(){
      return this.$store.getters.getUser; // Ottieni l'utente dallo store;
    },
    showNavbar() {
      // Nascondi la navbar se il percorso è "/login"
      return this.$route.path !== '/'; 
    },
  },
  mounted() {
    this.user = sessionStorage.getItem('User'); // Recupera il nome utente dalla sessionStorage
    this.profile = sessionStorage.getItem('Profile') || 0;
    this.role = sessionStorage.getItem('Role') || '';
    this.customers = sessionStorage.getItem('Customers') || null;        
  },  
  methods: {
    ...mapMutations(['setAuthenticated']), // Mappa la mutazione per l'autenticazione

    logout() {
      sessionStorage.removeItem('User'); // Rimuovi il nome utente dalla sessionStorage
      
      this.setAuthenticated(false); // Imposta l'autenticazione su false
      this.$router.push('/'); // Reindirizza alla pagina di login
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  cursor: pointer; /* Cambia il cursore in una mano */
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
